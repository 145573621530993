import { createSlice } from "@reduxjs/toolkit"

export const user = createSlice({
  name: "user",
  initialState: {
    value: undefined,
  },
  reducers: {
    setUser: (state, action) => {
      state.value = action.payload
    },
    updateUser: (state, action) => {
      state.value = { ...state.value, ...action.payload }
    },
    addUserProject: (state, action) => {
      state.value.projects.items.push(action.payload)
    },
    addUserResource: (state, action) => {
      state.value.resources.items.push(action.payload)
    },
    updateUserProject: (state, action) => {
      const { id } = action.payload
      const index = state.value.projects.items.findIndex(
        project => project.id === id
      )
      state.value.projects.items[index] = action.payload
    },
    updateUserResource: (state, action) => {
      const { id } = action.payload
      const index = state.value.resources.items.findIndex(
        resource => resource.id === id
      )
      state.value.resources.items[index] = action.payload
    },
  },
})

export const {
  setUser,
  addUserProject,
  updateUserProject,
  addUserResource,
  updateUserResource,
  updateUser,
} = user.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectUser = state => state.user.value
export const selectProject = id => state => {
  return state?.user?.value?.projects?.items.find(item => item.id === id)
}
export const selectResource = id => state => {
  return state?.user?.value?.resources?.items.find(item => item.id === id)
}

export default user.reducer
