import config from "../aws-exports"
import { siteUrl } from "../constants/site"

var urlsIn = [
  `${siteUrl}dashboard/`,
  "http://localhost:8000/dashboard/",
  ...config.oauth.redirectSignIn.split(","),
]
console.log({ urlsIn: urlsIn })
var urlsOut = [
  ...config.oauth.redirectSignOut.split(","),
  siteUrl,
  "http://localhost:8000/",
]
const oauth = {
  domain: config.oauth.domain,
  scope: config.oauth.scope,
  redirectSignIn: config.oauth.redirectSignIn,
  redirectSignOut: config.oauth.redirectSignOut,
  responseType: config.oauth.responseType,
}

function findUrlForEnv(urlStrings, isLocal) {
  console.log({ urlStrings })
  if (urlStrings.length === 1) return urlStrings[0]

  const re = isLocal ? /^http:\/\/localhost/ : /^https:\/\//
  const url = urlStrings.filter(urlString => urlString.match(re))
  // .map(urlString => new URL(urlString))
  if (!url) throw new Error("No valid URL found: " + urlStrings.join(","))
  return url[0]
}

function isDevelopment() {
  const { NODE_ENV } = process.env
  return NODE_ENV === "development"
}

oauth.redirectSignIn = findUrlForEnv(urlsIn, isDevelopment())
oauth.redirectSignOut = findUrlForEnv(urlsOut, isDevelopment())

var configUpdate = config
configUpdate.oauth = oauth

console.log({ configUpdate })

export default configUpdate
