/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:c82befba-44fb-4172-9ef0-9695c1e2698e",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_UXAL3bj5G",
    "aws_user_pools_web_client_id": "5ob3aulufgrei5aqn7tg2ibtto",
    "oauth": {
        "domain": "auth.rv.herve.shop",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://rv.herve.shop/dashboard/,http://localhost:8000/dashboard/",
        "redirectSignOut": "https://rv.herve.shop/,http://localhost:8000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_appsync_graphqlEndpoint": "https://tsnrpiy3nbdy7gn6e5dcyy5p3q.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "herveAuthApi",
            "endpoint": "https://h6066npc8b.execute-api.eu-west-1.amazonaws.com/master",
            "region": "eu-west-1"
        },
        {
            "name": "herveOpenApi",
            "endpoint": "https://imqde3nupf.execute-api.eu-west-1.amazonaws.com/master",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "gatsbyhomeshopstorage130839-master",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
