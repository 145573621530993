import React from "react"
import { useDispatch } from "react-redux"
import { setIsMobile } from "../app/isMobile"

export default function MobileDetector() {
  const dispatch = useDispatch()
  React.useEffect(() => {
    const userAgent =
      typeof window.navigator === "undefined" ? "" : navigator.userAgent
    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    )
    dispatch(setIsMobile(mobile))
  }, [])
  return null
}
