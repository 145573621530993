import { configureStore } from "@reduxjs/toolkit"
// import counterReducer from "../features/counter/counterSlice";
import projects from "./projects"
import user from "./user"
import cognitoUser from "./cognitoUser"
import isMobile from "./isMobile"

const createStore = () =>
  configureStore({
    reducer: {
      isMobile,
      projects,
      user,
      cognitoUser,
    },
  })

export default createStore
