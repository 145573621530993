const { createMuiTheme, responsiveFontSizes } = require("@material-ui/core")
require("typeface-montserrat")

let theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: "#092D5C",
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: "#0066ff",
      main: "#ff6363",
      // dark: will be calculated from palette.secondary.main,
      // contrastText: "#ffcc00",
    },
    background: {
      main: "rgb(230,230,230)",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    // contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    // tonalOffset: 0.2,
  },
  typography: {
    fontFamily: ["Montserrat"],
    allVariants: {
      color: "#13171C",
    },
  },
  // typography: {
  //   subtitle1: {
  //     fontSize: 12,
  //   },
  //   body1: {
  //     fontWeight: 500,
  //   },
  //   button: {
  //     fontStyle: "italic",
  //   },
  // },
})
theme = responsiveFontSizes(theme)

export default theme
