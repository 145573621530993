import { createSlice } from "@reduxjs/toolkit"

export const projects = createSlice({
  name: "projects",
  initialState: {
    items: [],
  },
  reducers: {
    setProjects: (state, action) => {
      state.items = action.payload
    },
  },
})

export const { setProjects } = projects.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectProjects = state => state.projects.items

export default projects.reducer
