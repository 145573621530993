import { createSlice } from "@reduxjs/toolkit"

export const isMobile = createSlice({
  name: "isMobile",
  initialState: {
    value: undefined,
  },
  reducers: {
    setIsMobile: (state, action) => {
      state.value = action.payload
    },
  },
})

export const { setIsMobile } = isMobile.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectIsMobile = state => state.isMobile.value

export default isMobile.reducer
