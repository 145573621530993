import React from "react"
import ErrorBoundary from "./src/components/ErrorBoundary"
import { Provider } from "react-redux"
// import { MuiThemeProvider } from "@material-ui/core"
import createStore from "./src/app/createStore"
// import theme from "./theme"
import AuthListener from "./src/components/AuthListener"
import MobileDetector from "./src/components/MobileDetector"

export default function Wrapper({ element }) {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore()
  return (
    <ErrorBoundary>
      <Provider store={store}>
        {/* <MuiThemeProvider theme={theme}> */}
        {element}
        <AuthListener />
        <MobileDetector />
      </Provider>
    </ErrorBoundary>
  )
}
