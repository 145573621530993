import { createSlice } from "@reduxjs/toolkit"

export const cognitoUser = createSlice({
  name: "cognitoUser",
  initialState: {
    value: undefined,
  },
  reducers: {
    setCognitoUser: (state, action) => {
      state.value = action.payload
    },
  },
})

export const { setCognitoUser } = cognitoUser.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectCognitoUser = state => state.cognitoUser.value

export default cognitoUser.reducer
