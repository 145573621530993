import React from "react"
import { useDispatch } from "react-redux"
import Amplify, { Auth, Hub } from "aws-amplify"
import { setCognitoUser } from "../app/cognitoUser"
import { API, graphqlOperation } from "aws-amplify"
import { setUser } from "../app/user"
import awsconfig from "../utils/updateAmplifyConfig"

Amplify.configure(awsconfig)

const getShopAdminUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      image
      picture
      availability
      calendarAccess
      selectedCalendars
      family_name
      given_name
      phone_number
      axonautId
      shortDescription
      createdAt
      updatedAt
      sentInvites {
        items {
          id
          toEmail
          createdAt
        }
      }
      receivedInvites {
        items {
          id
          toEmail
          createdAt
        }
      }
      projects {
        items {
          id
          cgv
          policy
          canShip
          payedSeats
          payedVideoSeats
          planCanSell
          planCanVisio
          userID
          availability
          createdAt
          capabilities
          charges_enabled
          meta
          name
          reminders
          slug
          updatedAt
          resources {
            items {
              id
              resource {
                id
                createdAt
                updatedAt
                bookDuration
                bufferDuration
                bookings {
                  items {
                    id
                    props
                    createdAt
                    updatedAt
                  }
                }
                resourceType
                workUsers {
                  items {
                    id
                    user {
                      id
                      family_name
                      given_name
                      image
                      picture
                    }
                  }
                }
              }
            }
          }
        }
        nextToken
      }
      workResources {
        items {
          id
          userID
          resourceID
          createdAt
          updatedAt
          resource {
            createdAt
            id
            owner
            updatedAt
          }
        }
        nextToken
      }
      ownedResources {
        items {
          id
          owner
          projects {
            nextToken
          }
          updatedAt
          workUsers {
            items {
              user {
                id
                family_name
                given_name
                calendarAccess
              }
            }
          }
          bookings {
            nextToken
          }
        }
        nextToken
      }
      ownedUsers {
        items {
          id
          availability
          calendarAccess
          family_name
          given_name
          image
          picture
        }
      }
      bookings {
        items {
          id
          owner
          resourceID
          projectID
          props
          start
          end
          bookType
          status
          recipe
          totalAmount
          deliveryPrice
          canShip
          hasSell
          customerEmail
          customerName
          customerPhone
          sellerEmail
          bookReason
          delivery
          meetUrl
          shipping {
            name
          }
          payment_status
          createdAt
          updatedAt
          seller {
            id
            owner
            email
            availability
            calendarAccess
            synced
            family_name
            selectedCalendars
            given_name
            shortDescription
            image
            picture
            phone_number
            resourceID
            axonautId
            createdAt
            updatedAt
          }
          project {
            id
            payedSeats
            userID
            availability
            published
            createdAt
            meta
            name
            owner
            reminders
            slug
            updatedAt
            canShip
            type
            stripeID
            stripeConnected
          }
          resource {
            id
            owner
            name
            description
            active
            resourceType
            bookDuration
            bufferDuration
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`

const getShopEmployeeUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      selectedCalendars
      calendarAccess
      image
      picture
      availability
      family_name
      given_name
      phone_number
      shortDescription
      createdAt
      updatedAt
      receivedInvites {
        items {
          id
          toEmail
          createdAt
        }
      }
      projects {
        items {
          id
          userID
          availability
          createdAt
          meta
          name
          reminders
          slug
          updatedAt
          resources {
            items {
              id
              resource {
                id
                resourceType
                createdAt
                updatedAt
                workUsers {
                  items {
                    id
                  }
                }
                bookings {
                  items {
                    id
                    props
                    createdAt
                    updatedAt
                  }
                }
              }
            }
          }
        }
        nextToken
      }
      workResources {
        items {
          id
          userID
          resourceID
          createdAt
          updatedAt
          resource {
            id
            owner
            updatedAt
          }
        }
        nextToken
      }
      ownedResources {
        items {
          id
          owner
          projects {
            nextToken
          }
          updatedAt
          workUsers {
            items {
              user {
                id
                family_name
                given_name
              }
            }
          }
          shopAdmin {
            id
            family_name
            given_name
            phone_number
            resourceID
            createdAt
            updatedAt
            owner
          }
          bookings {
            nextToken
          }
        }
        nextToken
      }
      bookings {
        items {
          id
          owner
          resourceID
          projectID
          props
          start
          end
          bookType
          status
          recipe
          totalAmount
          deliveryPrice
          canShip
          hasSell
          customerEmail
          customerName
          customerPhone
          sellerEmail
          bookReason
          delivery
          meetUrl
          shipping {
            name
          }
          payment_status
          createdAt
          updatedAt
          seller {
            id
            owner
            email
            availability
            calendarAccess
            synced
            family_name
            selectedCalendars
            given_name
            shortDescription
            image
            picture
            phone_number
            resourceID
            createdAt
            updatedAt
          }
          project {
            id
            userID
            availability
            published
            createdAt
            meta
            name
            owner
            reminders
            slug
            updatedAt
            canShip
            type
            stripeID
            stripeConnected
          }
          resource {
            id
            owner
            name
            description
            active
            resourceType
            bookDuration
            bufferDuration
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`

export const fetchUserData = (cognitoUser, dispatch) => {
  const { username, groups } = cognitoUser
  const isShopAdmin = groups.includes("ShopsAdmin")
  return API.graphql(
    graphqlOperation(isShopAdmin ? getShopAdminUser : getShopEmployeeUser, {
      id: username,
    })
  )
    .then(response => {
      console.log("gotDynamoUser", response.data.getUser)
      dispatch(setUser(response.data.getUser))
      return true
    })
    .catch(err => console.warn(err))
}

const onAuth = dispatch => ({ attributes, username, ...rest }) => {
  console.log({ rest })
  const groups =
    rest.signInUserSession.accessToken.payload["cognito:groups"] || []
  const cognitoUser = {
    ...attributes,
    groups,
    username,
  }
  dispatch(setCognitoUser(cognitoUser))
  // API.graphql(graphqlOperation())
  // restAuth("get", "/auth/test")
  //   .then(response => console.log({ response }))
  //   .catch(err => console.warn(err))
  fetchUserData(cognitoUser, dispatch)
  // API.graphql(
  //   graphqlOperation(isShopAdmin ? getShopAdminUser : getShopEmployeeUser, {
  //     id: attributes.sub,
  //   })
  // ).then(response => {
  //   console.log("gotDynamoUser", response.data.getUser)
  //   dispatch(setUser(response.data.getUser))
  // })
  console.log("startup", attributes)
}

const listener = dispatch => data => {
  switch (data.payload.event) {
    case "signIn":
      console.log("user signed in", data)
      Auth.currentAuthenticatedUser().then(onAuth(dispatch))
      break
    case "signUp":
      console.log("user signed up")
      break
    case "signOut":
      console.log("user signed out")
      dispatch(setCognitoUser(undefined))
      dispatch(setUser(undefined))
      break
    case "signIn_failure":
      console.warn("user sign in failed")
      break
    case "tokenRefresh":
      // dispatch(GetCognitoUser.action(data.payload.data.attributes));
      // dispatch(GetDynamoUser.action(data.payload.data.attributes.sub));
      console.log("token refresh succeeded")
      break
    case "tokenRefresh_failure":
      console.warn("token refresh failed")
      break
    case "configured":
      console.log("the Auth module is configured")
      break
    default:
      console.warn("unknown event", JSON.stringify(data, null, 2))
  }
}

export default function AuthListener() {
  const dispatch = useDispatch()
  React.useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(onAuth(dispatch))
      .catch(err => console.log(err, "user is not authenticated"))
  }, [dispatch])
  Hub.listen("auth", listener(dispatch))
  return null
}
